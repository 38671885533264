document.addEventListener('DOMContentLoaded', function () {
  toggleModal('hamburger');
  toggleModal('close');
}, false);

const blackBg = document.getElementById('blackBg')

const toggleModal = (idName) => {
  const obj = document.getElementById(idName);

  obj.addEventListener('click', function () {
    if (obj.id === 'hamburger') {
      blackBg.setAttribute('style', 'opacity: 1; visibility: visible;');
    } else if (obj.id === 'close') {
      blackBg.classList.add('visible-xs');
      blackBg.removeAttribute('style');
    }
  }, false);
}

